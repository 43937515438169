.rte:after {
  clear: both;
  content: '';
  display: block;
}

.rte > p:first-child {
  margin-top: 0;
}

.rte > p:last-child {
  margin-bottom: 0;
}

.rte table {
  table-layout: fixed;
}

@media screen and (min-width: 750px) {
  .rte table td {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.rte img {
  height: auto;
  max-width: 100%;
  border: var(--media-border-width) solid rgba(var(--color-foreground), var(--media-border-opacity));
  border-radius: var(--media-radius);
  box-shadow: var(--media-shadow-horizontal-offset) var(--media-shadow-vertical-offset) var(--media-shadow-blur-radius) rgba(var(--color-shadow), var(--media-shadow-opacity));
  margin-bottom: var(--media-shadow-vertical-offset);
}

.rte ul,
.rte ol {
  list-style-position: inside;
  padding-left: 2rem;
}

.rte li {
  list-style: inherit;
}

.rte li:last-child {
  margin-bottom: 0;
}

.rte a {
  color: rgba(var(--color-link), var(--alpha-link));
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness var(--duration-short) ease;
}

.rte a:hover {
  color: rgb(var(--color-link));
  text-decoration-thickness: 0.2rem;
}

.rte blockquote {
  display: inline-flex;
}

.rte blockquote > * {
  margin: -0.5rem 0 -0.5rem 0;
}
